<template>
  <v-dialog
    v-model="dialogNovoEditPerfil"
    @click:outside="$emit('update:dialogNovoEditPerfil', false)"
    @keydown.esc="$emit('update:dialogNovoEditPerfil', false)"
    max-width="500"
  >
    <v-card tile :loading="loading">
      <v-card-title class="pa-4 font-weight-bold accent">
        {{ adicionar ? "Adicionar" : "Editar" }} Perfil
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('update:dialogNovoEditPerfil', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- <v-card-text class="px-5 py-6">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="perfil.descricao"
              label="Descricao"
              dense
              
              hide-details
              required
              :rules="formRules"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-card
              outlined
              height="40px"
              class="pa-3 d-flex justify-space-between align-center"
            >
              <v-subheader
                style="height: auto !important"
                class="ma-0 pa-0 body-1"
                >Status</v-subheader
              >

              <v-switch
                class="ma-0 pa-0"
                hide-details
                :false-value="0"
                :true-value="1"
                v-model="perfil.status"
              ></v-switch>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text> -->

      <v-card-text class="px-5 py-8">
        <v-form ref="form" v-model="validForm" lazy-validation>
          <v-text-field dense readonly value="Status:">
            <template v-slot:append>
              <v-switch
                v-model="perfil.status"
                :false-value="0"
                :true-value="1"
                class="mt-0 pt-0"
                hide-details
                required
                :rules="formRules"
              >
              </v-switch>
            </template>
          </v-text-field>

          <v-text-field
            v-model="perfil.descricao"
            label="Descricao"
            dense
            :rules="formRules"
            class="mt-10"
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn @click="$emit('update:dialogNovoEditPerfil', false)">
          Cancelar
        </v-btn>

        <v-spacer></v-spacer>
        <v-btn
          @click="adicionar ? createPerfil() : updatePerfil()"
          :disabled="!validForm"
          color="secondary"
          class="white--text"
        >
          {{ adicionar ? "Adicionar" : "Atualizar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { postPerfil, putPerfil } from "@/api/admin/perfis.js";
import { inputRequired } from "@/helpers/utils.js";
export default {
  name: "NovoEditPerfis",

  props: {
    dialogNovoEditPerfil: {
      type: Boolean,
      required: true,
    },
    adicionar: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
    },
  },

  data() {
    return {
      perfil: {},
      loading: false,
      validForm: true,
      formRules: [inputRequired],
    };
  },

  computed: {},

  methods: {
    updatePerfil() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        putPerfil(this.perfil.id, this.perfil)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Perfil atualizado com sucesso!");
              this.$emit("fetch-perfis");
              this.$emit("update:dialogNovoEditPerfil", false);
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    createPerfil() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        postPerfil(this.perfil)
          .then((response) => {
            if (response.status === 201) {
              this.$toast.success("Perfil atualizado com sucesso!");
              this.$emit("fetch-perfis");
              this.$emit("update:dialogNovoEditPerfil", false);
              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },

  mounted() {
    if (this.item) {
      this.perfil = this.item;
    }
  },
};
</script>

<style lang="scss"></style>
